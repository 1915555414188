var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "mail-waiting-for-state-component" } },
    [
      _c("slide-heading", { attrs: { "html-heading": _vm.heading } }),
      _c("div", { staticClass: "description" }, [
        _c("p", [
          _vm._v(
            "\n      Once your formation paperwork arrives at the state office,\n      it will take about " +
              _vm._s(_vm.filedInTime) +
              " to process.\n    "
          ),
        ]),
        _c("p", [
          _vm._v(
            "\n      The state will send you confirmation of approval. You can also check\n      the state's online database to find out if your filing was approved.\n    "
          ),
        ]),
        _c("br"),
        _c("b", [_vm._v("Next Steps")]),
        _c("p", [
          _vm._v(
            "\n      After you get your approved documents returned and in-hand, continue on to the next step.\n    "
          ),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "button-container" },
        [
          _c(
            "router-link",
            { attrs: { to: { name: "root" } } },
            [
              _c("b-button", { staticClass: "home-button" }, [
                _c("span", [_vm._v("Take me to my account Home for now.")]),
              ]),
            ],
            1
          ),
          _c("p", [_vm._v("- or -")]),
          _c(
            "b-button",
            {
              staticClass: "next-button",
              attrs: { variant: "primary", "aria-label": "next button" },
              on: { click: _vm.nextSlide },
            },
            [_c("span", [_vm._v("I have my filed documents back.")])]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }