<template>
  <div id="mail-waiting-for-state-component">
    <slide-heading
      :html-heading="heading"
    />

    <div class="description">
      <p>
        Once your formation paperwork arrives at the state office,
        it will take about {{ filedInTime }} to process.
      </p>
      <p>
        The state will send you confirmation of approval. You can also check
        the state's online database to find out if your filing was approved.
      </p>
      <br>
      <b>Next Steps</b>
      <p>
        After you get your approved documents returned and in-hand, continue on to the next step.
      </p>
    </div>

    <div class="button-container">
      <router-link :to="{ name: 'root' }">
        <b-button class="home-button">
          <span>Take me to my account Home for now.</span>
        </b-button>
      </router-link>

      <p>- or -</p>

      <b-button
        class="next-button"
        variant="primary"
        aria-label="next button"
        @click="nextSlide"
      >
        <span>I have my filed documents back.</span>
      </b-button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'MailWaitingForState',
  components: {
    SlideHeading: () => import('@/components/StagelineV2/shared/SlideHeading'),
  },
  computed: {
    ...mapGetters('stageline', ['currentStage']),
    filedInTime () {
      // TODO [STAG-2671] - Remove hardcoded slide/step names and just look at stage products
      const reviewAndFileStep = this.currentStage.steps.find(step => step?.name === 'form_the_company__verify_and_file')
      const productSlide = reviewAndFileStep?.slides.find(slide => slide?.name === 'form_the_company__verify_and_file__how_to_file')
      const diyProduct = productSlide?.products.find(product => product?.product_kind === 'diy')
      return `${diyProduct?.config?.docs_in?.days} days`
    },
    heading () {
      return `Wait for the state to process your filing.`
    },
  },
  methods: {
    nextSlide() {
      this.$emit('next-slide')
    },
  },
}
</script>

<style lang="scss">
#mail-waiting-for-state-component {
  .button-container {
    margin-top: 1.5rem;
    text-align: center;

    .home-button {
      background-color: #7D64D8;

      span {
        padding: 0 0.5rem;
      }
    }

    p {
      margin: 0.5rem;
    }

    .next-button {
      span {
        padding: 0 0.5rem;
      }
    }
  }
}
</style>
